// src/components/SuccessPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function SuccessPage() {
    return (
        <div className="container">
            <h1>Thank you for registering!</h1>
            <p>Your registration and payment were successful.</p>
            <Link to="/">
                <Button variant="primary">Back to Home</Button>
            </Link>
        </div>
    );
}

export default SuccessPage;
