import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Form, Button } from 'react-bootstrap';

function RegistrationForm() {
    const [formData, setFormData] = useState({
        companyName: '',
        contactName: '',
        website: '',
        email: '',
        phoneNumber: '',
        licensingDistrict: '',
    });
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/api/register', formData);
            if (response.status === 201) {
                console.log('Response Data:', response.data);
                setShowModal(true); // Show modal on success
            }
        } catch (error) {
            console.error('Error registering:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Register Your Company</h2>
            <Form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <Form.Group controlId="companyName">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="contactName">
                    <Form.Label>Contact Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                        type="text"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="licensingDistrict">
                    <Form.Label>Licensing District</Form.Label>
                    <Form.Control
                        type="text"
                        name="licensingDistrict"
                        value={formData.licensingDistrict}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    Register
                </Button>
            </Form>

            {/* Modal for Success */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Registration Successful</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your company has been registered successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RegistrationForm;
