import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RegistrationForm from './components/RegistrationForm';
import TaxiFareCalculator from './components/TaxiFareCalculator';
import SuccessPage from './components/SuccessPage';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LoadScriptNext } from '@react-google-maps/api'; // Import LoadScriptNext
import { ROUTES } from './routes/routes';
import './App.css'; // Assuming you have a CSS file for styles

function App() {
    return (
        <Provider store={store}>
            <Router>
                <div className="App taxi-striped-bg">
                    {/* Main Content */}
                    <div>
                        <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places']}>
                            <Routes>
                                <Route path={ROUTES.HOME} element={<TaxiFareCalculator />} />
                                <Route path={ROUTES.REGISTER} element={<RegistrationForm />} />
                                <Route path={ROUTES.SUCCESS} element={<SuccessPage />} />
                            </Routes>
                        </LoadScriptNext>
                    </div>
                </div>
            </Router>
        </Provider>
    );
}

export default App;
