// index.js
import { combineReducers } from 'redux';
import formReducer from './formReducer';

const rootReducer = combineReducers({
    form: formReducer,
    // You can add more reducers here
});

export default rootReducer;
