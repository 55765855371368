import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import '../styles/TaxiFareCalculator.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button
import RegistrationForm from './RegistrationForm'; // Import the RegistrationForm component
const containerStyle = {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0
};

const center = {
    lat: 52.3555, // Centered in the UK
    lng: -1.1743
};

const councils = {
    EDDC: {
        name: 'East Devon District Council',
        tariffs: {
            tariff1: { name: 'Tariff 1', firstMile: 5.96, subsequentMile: 2.73, description: 'TARIFF 1:- Weekday Daytime Rate (Applies on weekdays between 7 am and 7 pm)' },
            tariff2: { name: 'Tariff 2', firstMile: 6.93, subsequentMile: 3.3, description: 'TARIFF 2:- Evening/Night & Sunday Rate (Applies on weekdays between 7 pm and 7 am and on Sundays)' },
            tariff3: { name: 'Tariff 3', firstMile: 9.02, subsequentMile: 3.85, description: 'TARIFF 3:- Bank Holiday, Christmas and New Year Rate' }
        }
    },
    DDC: {
        name: 'Dorset District Council',
        tariffs: {
            tariff1: { name: 'Tariff 1', firstMile: 6.6, subsequentMile: 2.8, description: 'TARIFF 1:- Day Rate (7 am until 11 pm)' },
            tariff2: { name: 'Tariff 2', firstMile: 9.9, subsequentMile: 4.2, description: 'TARIFF 2:- Unsocial Hours' },
            tariff3: { name: 'Tariff 3', firstMile: 13.2, subsequentMile: 5.6, description: 'TARIFF 3:- Special Rate (Christmas & New Year)' }
        }
    }
};

const TaxiFareCalculator = () => {
    const [startLocation, setStartLocation] = useState('');
    const [endLocation, setEndLocation] = useState('');
    const [selectedCouncil, setSelectedCouncil] = useState('EDDC');
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [fare, setFare] = useState(null);
    const [distance, setDistance] = useState(null);
    const [duration, setDuration] = useState(null);
    const [isCalculated, setIsCalculated] = useState(false);
    const handleOpenWebsite = () => {
        window.open("https://www.taxi-local.com/site2/find-local-taxis", "_blank");
      };
    // Fetch the user's current location
    const fetchCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const currentLocation = `${latitude},${longitude}`;
                    setStartLocation(currentLocation);
                },
                (error) => {
                    alert("Unable to retrieve your location. Please enable location services.");
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const calculateFare = (distance, tariffs) => {
        const fareResults = {};
        Object.keys(tariffs).forEach(tariffKey => {
            const tariff = tariffs[tariffKey];
            const firstMileFare = tariff.firstMile;
            const subsequentMilesFare = distance > 1 ? (distance - 1) * tariff.subsequentMile : 0;
            fareResults[tariffKey] = firstMileFare + subsequentMilesFare;
        });
        return fareResults;
    };

    const handleCalculateRoute = useCallback(() => {
        if (startLocation && endLocation) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: startLocation,
                    destination: endLocation,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(response);
                        const route = response.routes[0].legs[0];
                        const distanceInMiles = route.distance.value / 1609.34; // Convert meters to miles
                        const durationInMinutes = route.duration.value / 60; // Convert seconds to minutes

                        setDistance(distanceInMiles);
                        setDuration(durationInMinutes);

                        const councilTariffs = councils[selectedCouncil].tariffs;
                        setFare(calculateFare(distanceInMiles, councilTariffs));
                        setIsCalculated(true); // Set the state to true when calculation is done
                    } else {
                        alert("Directions request failed due to " + status);
                    }
                }
            );
        } else {
            alert("Please enter both start and end locations.");
        }
    }, [startLocation, endLocation, selectedCouncil]);

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    const openGoogleMaps = () => {
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(startLocation)}&destination=${encodeURIComponent(endLocation)}&travelmode=driving`;
        window.open(mapsUrl, '_blank');
    };

    const resetCalculator = () => {
        setStartLocation('');
        setEndLocation('');
        setSelectedCouncil('EDDC');
        setDirectionsResponse(null);
        setFare(null);
        setDistance(null);
        setDuration(null);
        setIsCalculated(false); // Reset the state to show the form again
    };

    // State for modal visibility
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const handleShowModal = () => setShowRegistrationModal(true);
    const handleCloseModal = () => setShowRegistrationModal(false);

    return (
        <div className="map-container" style={{ alignItems: 'center', backgroundColor: '#F5F5F5' }}>
                <div className="row justify-content-center">
                    <div className="calculation col-md- col-lg-6 p-4">

                        {!isCalculated && (
                            <>
                                <h4 className="text-center" style={{ backgroundColor: 'yellow' }}> <strong>TAXI-LOCAL.COM</strong></h4>
                                <h2 className="text-center mb-4">Taxi Fare Calculator</h2>
                                    <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div className="mb-3">
    <label htmlFor="start">Start Location:</label>
    <div className="input-group">
        <Autocomplete 
            onPlaceChanged={() => setStartLocation(document.getElementById('start').value)}
            options={{
                componentRestrictions: { country: 'uk' }, // Restrict to the UK
            }}
        >
            <input
                type="text"
                id="start"
                className="form-control"
                placeholder="Enter start location"
                value={startLocation}
                onChange={(e) => setStartLocation(e.target.value)}
                required
            />
        </Autocomplete>
        {/* Clear button */}
        {startLocation && (
            <button 
                type="button" 
                className="btn btn-outline-secondary"
                onClick={() => setStartLocation('')}
            >
                X
            </button>
        )}
    </div>
    <button 
        type="button" 
        className="btn btn-outline-primary mt-2" 
        onClick={fetchCurrentLocation}
    >
        Use Current Location
    </button>
</div>

                                    <div className="mb-3">
                                        <label htmlFor="end">End Location:</label>
                                        <Autocomplete onPlaceChanged={() => setEndLocation(document.getElementById('end').value)}options={{
        componentRestrictions: { country: 'uk' }, // Restrict to the UK
    }}>
                                            <input
                                                type="text"
                                                id="end"
                                                className="form-control"
                                                placeholder="Enter end location"
                                                value={endLocation}
                                                onChange={(e) => setEndLocation(e.target.value)}
                                                required
                                            />
                                        </Autocomplete>
                                    </div>
                                    <div className="mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '100%' }}>
                                            <label>Select District Council:</label>
                                            <select
                                                className="form-select"
                                                onChange={(e) => setSelectedCouncil(e.target.value)}
                                                value={selectedCouncil}
                                            >
                                                {Object.keys(councils).map(councilKey => (
                                                    <option key={councilKey} value={councilKey}>
                                                        {councils[councilKey].name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-success" style={{ width: '100%' }} onClick={handleCalculateRoute}>Calculate Fare</button>
                                </form>
                                <div className="text-center mt-4">
                                    <Button variant="warning" onClick={handleShowModal}>
                                        Taxi Operator? Register Now!
                                    </Button>
                                </div>
                                {/* Modal for Registration Form */}
                                <Modal show={showRegistrationModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>For Taxi Operators</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <RegistrationForm />
                                    </Modal.Body>
                                </Modal>
                            </>
                        )}

                        {isCalculated && fare !== null && (
                            <div className="fare-calculation">
                                <div className="fare-results mt-10 pt-10">
                                    <h3 className="text-center" style={{ backgroundColor: 'yellow' }}> <strong>TAXI-LOCAL.COM</strong></h3>
                                    <h3 className="text-center">Fare Calculation</h3>
                                    <h5 className="text-center mb-4">{startLocation} to {endLocation}</h5>
                                    <p className="text-center"><strong>Distance: {Math.round(distance.toFixed(1))} miles</strong></p>
                                    <p className="text-center"><strong>Duration: {Math.round(duration.toFixed(2))} minutes</strong></p>
                                    
                                    {Object.keys(fare).map(tariffKey => (
                                        <div className="text-center" key={tariffKey}>
                                            <p><strong> {councils[selectedCouncil].tariffs[tariffKey].name}: £{Math.round(fare[tariffKey].toFixed(2))}</strong></p>
                                        </div>
                                    ))}
                                    <div className="text-center mt-4">
                                        <div className="text-center mb-2">
                                            <button className="btn btn-secondary mx-8" onClick={resetCalculator}>Reset</button>
                                        </div>
                                        <div className="text-center mb-2">
                                            <button className="btn btn-success mx-8" onClick={openGoogleMaps}>Navigate</button>
                                        </div>
                                        <div className="text-center mb-2">
                                        <button className="btn btn-primary mx-8" onClick={handleOpenWebsite}>Find Taxi Availability</button>
                                    </div>
                                    </div>
                                    {Object.keys(fare).map(tariffKey => (
                                    <div className="small text-muted" key={tariffKey}>
                                        <p className="small text-muted">{councils[selectedCouncil].tariffs[tariffKey].description}</p>
                                    </div>
                                ))}
                                    <div className="disclaimer">
                                        <p className="small text-muted mt-4"><strong>DISCLAIMER:</strong> The fares calculated by this tool are estimates based on the shortest driving distance and standard tariffs provided by the selected council. Actual fares may vary due to factors such as traffic conditions, road closures, detours, or delays. Additional charges may apply for waiting time, tolls, or other surcharges.</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    );
};

export default TaxiFareCalculator;